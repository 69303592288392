// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  myCustomPaginatin: "",
  loading: false,
  token: JSON.parse(localStorage.getItem("accessToken")),
  found_results: "",
  entries_per_page: "",
  total_results: "",
  updatePagination: 0,
};

// console.log('sssssssssssss', selectedUser)

const operators = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_DATA":
      return {
        ...state,
        allData: action.data,
        myCustomPaginatin: action.total,
        entries_per_page: action.entries_per_page,
        found_results: action?.found_results,
        total_results: action?.total_results,
      };
    case "DELETE_OPERATOR_SUCCESS":
      return {
        ...state,
        allData: state.allData.filter((item) => item._id !== action.payload),
      };
    case "START_LOADING":
      return { ...state, loading: true };
    case "END_LOADING":
      return { ...state, loading: false };
    case "PAGE_CHANGE":
      return { ...state, allUsers: [] };
    default:
      return { ...state };
  }
};
export default operators;
