// ** Initial State
const initialState = {
  allUsers: [],
  data: [],
  total: 1,
  params: {},
  currentUser: null,
  myCustomPaginatin: '',
  selectedUser: localStorage.getItem("selectedAppUser") === 'undefined' ? null : JSON.parse(localStorage.getItem("selectedAppUser")),
  loading: false,
  token: JSON.parse(localStorage.getItem("accessToken")),
  found_results: '',
  entries_per_page: '',
  total_results: '',
  updatePagination: 0

}

// console.log('sssssssssssss', selectedUser)

const users = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      // console.log(action.data)
      return { ...state, allUsers: action.data, myCustomPaginatin: action.total, entries_per_page: action.entries_per_page, found_results: action?.found_results, total_results: action?.total_results }
    case 'GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_USER':
      // console.log(action)
      return { ...state, selectedUser: action.selectedUser }
    case 'ADD_USER':
      return { ...state, allUsers: action.data }
    case 'DELETE_USER':
      return { ...state, updatePagination: action.data }
    case 'START_LOADING':
      return { ...state, loading: true }
    case 'END_LOADING':
      return { ...state, loading: false }
    case 'PAGE_CHANGE':
      return { ...state, allUsers: [] }
    default:
      return { ...state }
  }
}
export default users
