import { store } from "@store/storeConfig/store";
// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  currentUser: null,
  selectedUser:
    localStorage.getItem("selectedUser") === "undefined"
      ? null
      : JSON.parse(localStorage.getItem("selectedUser")),
  customTotal: null,
  token: JSON.parse(localStorage.getItem("accessToken")),
  getError: false,
  errorMsg: "Error occured!!",
  otpSuccess: false,
  setNewPassword: false,
  found_results: "",
  entries_per_page: "",
  total_results: "",
  fetching: false,
  updatePagination: 0,
  isTokenExpire: false,
};
// console.log(store)

// console.log('sssssssssssss', selectedUser)

const spamWords = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_SPAMWORDS":
      return {
        ...state,
        allData: action.data,
        customTotal: action.total,
        entries_per_page: action.entries_per_page,
        found_results: action?.found_results,
        total_results: action?.total_results,
      };
    case "DELETE_SPAMWORD":
      return { ...state, updatePagination: action.data };
    default:
      return { ...state };
  }
};
export default spamWords;
