import mock from '../mock'
import { paginateArray } from '../utils'
import { useDispatch } from 'react-redux'


// const data = {users: [
// {id: 1,
// fullName:"user2",
// password:"141a923dfddb1c05514620090610aac249ae1da1af6e4fbe3039ca5b43478b94e555b211230fc059ff32fbb6bcfacdfc9a3f407e035584889badc9fbe7a06a6b",
// role:"user",
// dob:"2021-12-09",
// premium:"false",
// addOn:"false",
// username:"user2",
// country:"El Salvador",
// contact:"09111111111",
// email:"user2@gmail.com",
// currentPlan:"ssss",
// package: 'statndard',
// status:"active",
// addOn: "true",
// avatar:"https://i.pinimg.com/736x/25/78/61/25786134576ce0344893b33a051160b1.jpg"
// }, {
// id: 2,
// fullName:"user3",
// password:"e942f62f52f75e6781ec58b14a8f45a8962bb4c0a3cd21cba501ccac9a1e6f72bbbfefb1624fa9bdc42db316f75636dc5b2b3050892a17702e8a269f17b3d343",
// role:"user",
// dob:"2021-12-09",
// premium:"false",
// addOn:"false",
// username:"user3",
// country:"El Salvador",
// contact:"09111111111",
// email:"user3@gmail.com",
// currentPlan:"ssss",
// status:"active",
// avatar:"https://i.pinimg.com/736x/25/78/61/25786134576ce0344893b33a051160b1.jpg"
// }
// ]}
// console.log(data)

// console.log(window.location.href.split("/")[4] === "newTesting")

// if (window.location.href.split("/")[4] === "newTesting") {

// const data = {user: []}
// console.log(data)

// const data = {}
// let data

//    fetch(`https://backend.mypagesmm.com/users?role=user`, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization:
//             "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2Mzg1ODA3YjEwMWFmMWUyZWIyMTRlMjgiLCJyb2xlIjoic3VwZXIgYWRtaW4iLCJpYXQiOjE2Njk3Nzc2NzMwMDEsImV4cCI6MTY2OTc3ODI3NzgwMX0.GzN8yg-RnVBqILyFye0B6-dA6KqKvWDqj09DxzvPJprUQiQ4QDcff0h8jKJNbpJ9mxzphhy8YUNznWQLVyNa1Vo__bRiObQqxnRNs5CNXbufC_xu0Yw_JgNRyz2xGovwJ4_PvGdRnK7pM5N-j8dsLijluUnQ_RE5yW1F4WKhOqGyM1s3SLO0BYm7Vb1Rx0XFpwBG18_xvygmQoEicMDm5BolTtJY9ud3I67243AelnsEx8yzSS5_j0hlPSEhstPGE76oJjrrArxT7zJJ_FwWGAaEdlo2Jgwm3u6BmtJsvabGNS7Dfksg5rN3dMtJgsqvrxBNfkNOGC0ZoqbDyhn3yPudb3jdoiLvpKSFnkWLPTyhJBsZgDniMFcD0CLq2fu1amXOvXpQFcZKZLCpsh51_B_zUVZKq87llSfDKvc98DLy9DwH2vHHwNd7DR-gzV3H8l6HX6xOUTPFvWNxYhiWfEUiY18quIpW45dB7zFxqYnO1XbrK1lFxJg25D0ptSnmrO_fW2q-zlMnwirUsQ64RtrFHIUMb-oT4CZALRPBLHqojPTzLDdz4abEMTLuP2ZqhsaZYx6Hma0VOZgy2yopFiL8Zu-Aujxsi-7AEAwstdidVb2alWQBtgYBn7TLZBAmVBbWgq93PcdNYCRHh8l4SVOOm37uUc2puabj70AouM8"
//         }
//       })
//         .then((res) => res.json())
//         .then((json) => {
//           console.log(json.users)
//           data.user = [...json.users]
//         })


// }
// const data = {
//   users: [
//     {
//       id: 1,
//       fullName: 'Galen Slixby',
//       company: 'Yotz PVT LTD',
//       password: '12345',
//       role: 'admin',
//       phone: '09785460219',
//       dob: '12.15.222',
//       premium: "true",
//       addOn: "true",
//       username: 'gslixby0',
//       country: 'El Salvador',
//       contact: '(479) 232-9151',
//       email: 'gslixby0@abc.net.au',
//       currentPlan: 'enterprise',
//       status: 'inactive',
//       avatar: ''
//     },
//     {
//       id: 2,
//       fullName: 'Halsey Redmore',
//       company: 'Skinder PVT LTD',
//       role: 'user',
//       phone: '08785460219',
//       dob: '13.15.22',
// premium: "false",
// addOn: "false",

//       username: 'hredmore1',
//       country: 'Albania',
//       contact: '(472) 607-9137',
//       email: 'hredmore1@imgur.com',
//       currentPlan: 'team',
//       status: 'pending',
//       avatar: require('@src/assets/images/avatars/10.png').default
//     },
//     {
//       id: 3,
//       fullName: 'Marjory Sicely',
//       company: 'Oozz PVT LTD',
//       role: 'maintainer',
//       dob: '14.15.22',
//       premium: "false",
//       addOn: "false",
//       username: 'msicely2',
//       country: 'Russia',
//       phone: '09785460219',
//       contact: '(321) 264-4599',
//       email: 'msicely2@who.int',
//       currentPlan: 'enterprise',
//       status: 'active',
//       avatar: require('@src/assets/images/avatars/1.png').default
//     },
//     {
//       id: 4,
//       fullName: 'Cyrill Risby',
//       company: 'Oozz PVT LTD',
//       role: 'maintainer',
//       username: 'crisby3',
//       country: 'China',
//       contact: '(923) 690-6806',
//       email: 'crisby3@wordpress.com',
//       currentPlan: 'team',
//       status: 'inactive',
//       avatar: require('@src/assets/images/avatars/9.png').default
//     },
//     {
//       id: 5,
//       fullName: 'Maggy Hurran',
//       company: 'Aimbo PVT LTD',
//       role: 'subscriber',
//       username: 'mhurran4',
//       country: 'Pakistan',
//       contact: '(669) 914-1078',
//       email: 'mhurran4@yahoo.co.jp',
//       currentPlan: 'enterprise',
//       status: 'pending',
//       avatar: require('@src/assets/images/avatars/10.png').default
//     },
//     {
//       id: 6,
//       fullName: 'Silvain Halstead',
//       company: 'Jaxbean PVT LTD',
//       role: 'author',
//       username: 'shalstead5',
//       country: 'China',
//       contact: '(958) 973-3093',
//       email: 'shalstead5@shinystat.com',
//       currentPlan: 'company',
//       status: 'active',
//       avatar: ''
//     },
//     {
//       id: 7,
//       fullName: 'Breena Gallemore',
//       company: 'Jazzy PVT LTD',
//       role: 'subscriber',
//       username: 'bgallemore6',
//       country: 'Canada',
//       contact: '(825) 977-8152',
//       email: 'bgallemore6@boston.com',
//       currentPlan: 'company',
//       status: 'pending',
//       avatar: ''
//     },
//     {
//       id: 8,
//       fullName: 'Kathryne Liger',
//       company: 'Pixoboo PVT LTD',
//       role: 'author',
//       username: 'kliger7',
//       country: 'France',
//       contact: '(187) 440-0934',
//       email: 'kliger7@vinaora.com',
//       currentPlan: 'enterprise',
//       status: 'pending',
//       avatar: require('@src/assets/images/avatars/9.png').default
//     },
//     {
//       id: 9,
//       fullName: 'Franz Scotfurth',
//       company: 'Tekfly PVT LTD',
//       role: 'subscriber',
//       username: 'fscotfurth8',
//       country: 'China',
//       contact: '(978) 146-5443',
//       email: 'fscotfurth8@dailymotion.com',
//       currentPlan: 'team',
//       status: 'pending',
//       avatar: require('@src/assets/images/avatars/2.png').default
//     },
//     {
//       id: 10,
//       fullName: 'Jillene Bellany',
//       company: 'Gigashots PVT LTD',
//       role: 'maintainer',
//       username: 'jbellany9',
//       country: 'Jamaica',
//       contact: '(589) 284-6732',
//       email: 'jbellany9@kickstarter.com',
//       currentPlan: 'company',
//       status: 'inactive',
//       avatar: require('@src/assets/images/avatars/9.png').default
//     },
//     {
//       id: 11,
//       fullName: 'Jonah Wharlton',
//       company: 'Eare PVT LTD',
//       role: 'subscriber',
//       username: 'jwharltona',
//       country: 'United States',
//       contact: '(176) 532-6824',
//       email: 'jwharltona@oakley.com',
//       currentPlan: 'team',
//       status: 'inactive',
//       avatar: require('@src/assets/images/avatars/4.png').default
//     },
//     {
//       id: 12,
//       fullName: 'Seth Hallam',
//       company: 'Yakitri PVT LTD',
//       role: 'subscriber',
//       username: 'shallamb',
//       country: 'Peru',
//       contact: '(234) 464-0600',
//       email: 'shallamb@hugedomains.com',
//       currentPlan: 'team',
//       status: 'pending',
//       avatar: require('@src/assets/images/avatars/5.png').default
//     },
//     {
//       id: 13,
//       fullName: 'Yoko Pottie',
//       company: 'Leenti PVT LTD',
//       role: 'subscriber',
//       username: 'ypottiec',
//       country: 'Philippines',
//       contact: '(907) 284-5083',
//       email: 'ypottiec@privacy.gov.au',
//       currentPlan: 'basic',
//       status: 'inactive',
//       avatar: require('@src/assets/images/avatars/7.png').default
//     },
//     {
//       id: 14,
//       fullName: 'Maximilianus Krause',
//       company: 'Digitube PVT LTD',
//       role: 'author',
//       username: 'mkraused',
//       country: 'Democratic Republic of the Congo',
//       contact: '(167) 135-7392',
//       email: 'mkraused@stanford.edu',
//       currentPlan: 'team',
//       status: 'active',
//       avatar: require('@src/assets/images/avatars/9.png').default
//     },
//     {
//       id: 15,
//       fullName: 'Zsazsa McCleverty',
//       company: 'Kaymbo PVT LTD',
//       role: 'maintainer',
//       username: 'zmcclevertye',
//       country: 'France',
//       contact: '(317) 409-6565',
//       email: 'zmcclevertye@soundcloud.com',
//       currentPlan: 'enterprise',
//       status: 'active',
//       avatar: require('@src/assets/images/avatars/2.png').default
//     },
//     {
//       id: 16,
//       fullName: 'Bentlee Emblin',
//       company: 'Yambee PVT LTD',
//       role: 'author',
//       username: 'bemblinf',
//       country: 'Spain',
//       contact: '(590) 606-1056',
//       email: 'bemblinf@wired.com',
//       currentPlan: 'company',
//       status: 'active',
//       avatar: require('@src/assets/images/avatars/6.png').default
//     },
//     {
//       id: 17,
//       fullName: 'Brockie Myles',
//       company: 'Wikivu PVT LTD',
//       role: 'maintainer',
//       username: 'bmylesg',
//       country: 'Poland',
//       contact: '(553) 225-9905',
//       email: 'bmylesg@amazon.com',
//       currentPlan: 'basic',
//       status: 'active',
//       avatar: ''
//     },
//     {
//       id: 18,
//       fullName: 'Bertha Biner',
//       company: 'Twinte PVT LTD',
//       role: 'editor',
//       username: 'bbinerh',
//       country: 'Yemen',
//       contact: '(901) 916-9287',
//       email: 'bbinerh@mozilla.com',
//       currentPlan: 'team',
//       status: 'active',
//       avatar: require('@src/assets/images/avatars/7.png').default
//     },
//     {
//       id: 19,
//       fullName: 'Travus Bruntjen',
//       company: 'Cogidoo PVT LTD',
//       role: 'admin',
//       username: 'tbruntjeni',
//       country: 'France',
//       contact: '(524) 586-6057',
//       email: 'tbruntjeni@sitemeter.com',
//       currentPlan: 'enterprise',
//       status: 'active',
//       avatar: ''
//     },
//     {
//       id: 20,
//       fullName: 'Wesley Burland',
//       company: 'Bubblemix PVT LTD',
//       role: 'editor',
//       username: 'wburlandj',
//       country: 'Honduras',
//       contact: '(569) 683-1292',
//       email: 'wburlandj@uiuc.edu',
//       currentPlan: 'team',
//       status: 'inactive',
//       avatar: require('@src/assets/images/avatars/6.png').default
//     },
//     {
//       id: 21,
//       fullName: 'Selina Kyle',
//       company: 'Wayne Enterprises',
//       role: 'admin',
//       username: 'catwomen1940',
//       country: 'USA',
//       contact: '(829) 537-0057',
//       email: 'irena.dubrovna@wayne.com',
//       currentPlan: 'team',
//       status: 'active',
//       avatar: require('@src/assets/images/avatars/1.png').default
//     },
//     {
//       id: 22,
//       fullName: 'Jameson Lyster',
//       company: 'Quaxo PVT LTD',
//       role: 'editor',
//       username: 'jlysterl',
//       country: 'Ukraine',
//       contact: '(593) 624-0222',
//       email: 'jlysterl@guardian.co.uk',
//       currentPlan: 'company',
//       status: 'inactive',
//       avatar: require('@src/assets/images/avatars/8.png').default
//     },
//     {
//       id: 23,
//       fullName: 'Kare Skitterel',
//       company: 'Ainyx PVT LTD',
//       role: 'maintainer',
//       username: 'kskitterelm',
//       country: 'Poland',
//       contact: '(254) 845-4107',
//       email: 'kskitterelm@washingtonpost.com',
//       currentPlan: 'basic',
//       status: 'pending',
//       avatar: require('@src/assets/images/avatars/3.png').default
//     },
//     {
//       id: 24,
//       fullName: 'Cleavland Hatherleigh',
//       company: 'Flipopia PVT LTD',
//       role: 'admin',
//       username: 'chatherleighn',
//       country: 'Brazil',
//       contact: '(700) 783-7498',
//       email: 'chatherleighn@washington.edu',
//       currentPlan: 'team',
//       status: 'pending',
//       avatar: require('@src/assets/images/avatars/2.png').default
//     },
//     {
//       id: 25,
//       fullName: 'Adeline Micco',
//       company: 'Topicware PVT LTD',
//       role: 'admin',
//       username: 'amiccoo',
//       country: 'France',
//       contact: '(227) 598-1841',
//       email: 'amiccoo@whitehouse.gov',
//       currentPlan: 'enterprise',
//       status: 'pending',
//       avatar: ''
//     },
//     {
//       id: 26,
//       fullName: 'Hugh Hasson',
//       company: 'Skinix PVT LTD',
//       role: 'admin',
//       username: 'hhassonp',
//       country: 'China',
//       contact: '(582) 516-1324',
//       email: 'hhassonp@bizjournals.com',
//       currentPlan: 'basic',
//       status: 'inactive',
//       avatar: require('@src/assets/images/avatars/4.png').default
//     },
//     {
//       id: 27,
//       fullName: 'Germain Jacombs',
//       company: 'Youopia PVT LTD',
//       role: 'editor',
//       username: 'gjacombsq',
//       country: 'Zambia',
//       contact: '(137) 467-5393',
//       email: 'gjacombsq@jigsy.com',
//       currentPlan: 'enterprise',
//       status: 'active',
//       avatar: require('@src/assets/images/avatars/10.png').default
//     },
//     {
//       id: 28,
//       fullName: 'Bree Kilday',
//       company: 'Jetpulse PVT LTD',
//       role: 'maintainer',
//       username: 'bkildayr',
//       country: 'Portugal',
//       contact: '(412) 476-0854',
//       email: 'bkildayr@mashable.com',
//       currentPlan: 'team',
//       status: 'active',
//       avatar: ''
//     },
//     {
//       id: 29,
//       fullName: 'Candice Pinyon',
//       company: 'Kare PVT LTD',
//       role: 'maintainer',
//       username: 'cpinyons',
//       country: 'Sweden',
//       contact: '(170) 683-1520',
//       email: 'cpinyons@behance.net',
//       currentPlan: 'team',
//       status: 'active',
//       avatar: require('@src/assets/images/avatars/7.png').default
//     },
//     {
//       id: 30,
//       fullName: 'Isabel Mallindine',
//       company: 'Voomm PVT LTD',
//       role: 'subscriber',
//       username: 'imallindinet',
//       country: 'Slovenia',
//       contact: '(332) 803-1983',
//       email: 'imallindinet@shinystat.com',
//       currentPlan: 'team',
//       status: 'pending',
//       avatar: ''
//     },
//     {
//       id: 31,
//       fullName: 'Gwendolyn Meineken',
//       company: 'Oyondu PVT LTD',
//       role: 'admin',
//       username: 'gmeinekenu',
//       country: 'Moldova',
//       contact: '(551) 379-7460',
//       email: 'gmeinekenu@hc360.com',
//       currentPlan: 'basic',
//       status: 'pending',
//       avatar: require('@src/assets/images/avatars/1.png').default
//     },
//     {
//       id: 32,
//       fullName: 'Rafaellle Snowball',
//       company: 'Fivespan PVT LTD',
//       role: 'editor',
//       username: 'rsnowballv',
//       country: 'Philippines',
//       contact: '(974) 829-0911',
//       email: 'rsnowballv@indiegogo.com',
//       currentPlan: 'basic',
//       status: 'pending',
//       avatar: require('@src/assets/images/avatars/5.png').default
//     },
//     {
//       id: 33,
//       fullName: 'Rochette Emer',
//       company: 'Thoughtworks PVT LTD',
//       role: 'admin',
//       username: 'remerw',
//       country: 'North Korea',
//       contact: '(841) 889-3339',
//       email: 'remerw@blogtalkradio.com',
//       currentPlan: 'basic',
//       status: 'active',
//       avatar: require('@src/assets/images/avatars/8.png').default
//     },
//     {
//       id: 34,
//       fullName: 'Ophelie Fibbens',
//       company: 'Jaxbean PVT LTD',
//       role: 'subscriber',
//       username: 'ofibbensx',
//       country: 'Indonesia',
//       contact: '(764) 885-7351',
//       email: 'ofibbensx@booking.com',
//       currentPlan: 'company',
//       status: 'active',
//       avatar: require('@src/assets/images/avatars/4.png').default
//     },
//     {
//       id: 35,
//       fullName: 'Stephen MacGilfoyle',
//       company: 'Browseblab PVT LTD',
//       role: 'maintainer',
//       username: 'smacgilfoyley',
//       country: 'Japan',
//       contact: '(350) 589-8520',
//       email: 'smacgilfoyley@bigcartel.com',
//       currentPlan: 'company',
//       status: 'pending',
//       avatar: ''
//     },
//     {
//       id: 36,
//       fullName: 'Bradan Rosebotham',
//       company: 'Agivu PVT LTD',
//       role: 'subscriber',
//       username: 'brosebothamz',
//       country: 'Belarus',
//       contact: '(882) 933-2180',
//       email: 'brosebothamz@tripadvisor.com',
//       currentPlan: 'team',
//       status: 'inactive',
//       avatar: ''
//     },
//     {
//       id: 37,
//       fullName: 'Skip Hebblethwaite',
//       company: 'Katz PVT LTD',
//       role: 'admin',
//       username: 'shebblethwaite10',
//       country: 'Canada',
//       contact: '(610) 343-1024',
//       email: 'shebblethwaite10@arizona.edu',
//       currentPlan: 'company',
//       status: 'inactive',
//       avatar: require('@src/assets/images/avatars/9.png').default
//     },
//     {
//       id: 38,
//       fullName: 'Moritz Piccard',
//       company: 'Twitternation PVT LTD',
//       role: 'maintainer',
//       username: 'mpiccard11',
//       country: 'Croatia',
//       contact: '(365) 277-2986',
//       email: 'mpiccard11@vimeo.com',
//       currentPlan: 'enterprise',
//       status: 'inactive',
//       avatar: require('@src/assets/images/avatars/1.png').default
//     },
//     {
//       id: 39,
//       fullName: 'Tyne Widmore',
//       company: 'Yombu PVT LTD',
//       role: 'subscriber',
//       username: 'twidmore12',
//       country: 'Finland',
//       contact: '(531) 731-0928',
//       email: 'twidmore12@bravesites.com',
//       currentPlan: 'team',
//       status: 'pending',
//       avatar: ''
//     },
//     {
//       id: 40,
//       fullName: 'Florenza Desporte',
//       company: 'Kamba PVT LTD',
//       role: 'author',
//       username: 'fdesporte13',
//       country: 'Ukraine',
//       contact: '(312) 104-2638',
//       email: 'fdesporte13@omniture.com',
//       currentPlan: 'company',
//       status: 'active',
//       avatar: require('@src/assets/images/avatars/6.png').default
//     },
//     {
//       id: 41,
//       fullName: 'Edwina Baldetti',
//       company: 'Dazzlesphere PVT LTD',
//       role: 'maintainer',
//       username: 'ebaldetti14',
//       country: 'Haiti',
//       contact: '(315) 329-3578',
//       email: 'ebaldetti14@theguardian.com',
//       currentPlan: 'team',
//       status: 'pending',
//       avatar: ''
//     },
//     {
//       id: 42,
//       fullName: 'Benedetto Rossiter',
//       company: 'Mybuzz PVT LTD',
//       role: 'editor',
//       username: 'brossiter15',
//       country: 'Indonesia',
//       contact: '(323) 175-6741',
//       email: 'brossiter15@craigslist.org',
//       currentPlan: 'team',
//       status: 'inactive',
//       avatar: ''
//     },
//     {
//       id: 43,
//       fullName: 'Micaela McNirlan',
//       company: 'Tambee PVT LTD',
//       role: 'admin',
//       username: 'mmcnirlan16',
//       country: 'Indonesia',
//       contact: '(242) 952-0916',
//       email: 'mmcnirlan16@hc360.com',
//       currentPlan: 'basic',
//       status: 'inactive',
//       avatar: ''
//     },
//     {
//       id: 44,
//       fullName: 'Vladamir Koschek',
//       company: 'Centimia PVT LTD',
//       role: 'author',
//       username: 'vkoschek17',
//       country: 'Guatemala',
//       contact: '(531) 758-8335',
//       email: 'vkoschek17@abc.net.au',
//       currentPlan: 'team',
//       status: 'active',
//       avatar: ''
//     },
//     {
//       id: 45,
//       fullName: 'Corrie Perot',
//       company: 'Flipopia PVT LTD',
//       role: 'subscriber',
//       username: 'cperot18',
//       country: 'China',
//       contact: '(659) 385-6808',
//       email: 'cperot18@goo.ne.jp',
//       currentPlan: 'team',
//       status: 'pending',
//       avatar: require('@src/assets/images/avatars/3.png').default
//     },
//     {
//       id: 46,
//       fullName: 'Saunder Offner',
//       company: 'Skalith PVT LTD',
//       role: 'maintainer',
//       username: 'soffner19',
//       country: 'Poland',
//       contact: '(200) 586-2264',
//       email: 'soffner19@mac.com',
//       currentPlan: 'enterprise',
//       status: 'pending',
//       avatar: ''
//     },
//     {
//       id: 47,
//       fullName: 'Karena Courtliff',
//       company: 'Feedfire PVT LTD',
//       role: 'admin',
//       username: 'kcourtliff1a',
//       country: 'China',
//       contact: '(478) 199-0020',
//       email: 'kcourtliff1a@bbc.co.uk',
//       currentPlan: 'basic',
//       status: 'active',
//       avatar: require('@src/assets/images/avatars/1.png').default
//     },
//     {
//       id: 48,
//       fullName: 'Onfre Wind',
//       company: 'Thoughtmix PVT LTD',
//       role: 'admin',
//       username: 'owind1b',
//       country: 'Ukraine',
//       contact: '(344) 262-7270',
//       email: 'owind1b@yandex.ru',
//       currentPlan: 'basic',
//       status: 'pending',
//       avatar: ''
//     },
//     {
//       id: 49,
//       fullName: 'Paulie Durber',
//       company: 'Babbleblab PVT LTD',
//       role: 'subscriber',
//       username: 'pdurber1c',
//       country: 'Sweden',
//       contact: '(694) 676-1275',
//       email: 'pdurber1c@gov.uk',
//       currentPlan: 'team',
//       status: 'inactive',
//       avatar: ''
//     },
//     {
//       id: 50,
//       fullName: 'Beverlie Krabbe',
//       company: 'Kaymbo PVT LTD',
//       role: 'editor',
//       username: 'bkrabbe1d',
//       country: 'China',
//       contact: '(397) 294-5153',
//       email: 'bkrabbe1d@home.pl',
//       currentPlan: 'company',
//       status: 'active',
//       avatar: require('@src/assets/images/avatars/9.png').default
//     }
//   ]
// }
// let globalData;

// (async() => {
//   console.log('Ok')
//     const response = await fetch(`https://backend.mypagesmm.com/users?role=user`, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization:
//             "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2Mzg1ODA3YjEwMWFmMWUyZWIyMTRlMjgiLCJyb2xlIjoic3VwZXIgYWRtaW4iLCJpYXQiOjE2Njk3Nzc2NzMwMDEsImV4cCI6MTY2OTc3ODI3NzgwMX0.GzN8yg-RnVBqILyFye0B6-dA6KqKvWDqj09DxzvPJprUQiQ4QDcff0h8jKJNbpJ9mxzphhy8YUNznWQLVyNa1Vo__bRiObQqxnRNs5CNXbufC_xu0Yw_JgNRyz2xGovwJ4_PvGdRnK7pM5N-j8dsLijluUnQ_RE5yW1F4WKhOqGyM1s3SLO0BYm7Vb1Rx0XFpwBG18_xvygmQoEicMDm5BolTtJY9ud3I67243AelnsEx8yzSS5_j0hlPSEhstPGE76oJjrrArxT7zJJ_FwWGAaEdlo2Jgwm3u6BmtJsvabGNS7Dfksg5rN3dMtJgsqvrxBNfkNOGC0ZoqbDyhn3yPudb3jdoiLvpKSFnkWLPTyhJBsZgDniMFcD0CLq2fu1amXOvXpQFcZKZLCpsh51_B_zUVZKq87llSfDKvc98DLy9DwH2vHHwNd7DR-gzV3H8l6HX6xOUTPFvWNxYhiWfEUiY18quIpW45dB7zFxqYnO1XbrK1lFxJg25D0ptSnmrO_fW2q-zlMnwirUsQ64RtrFHIUMb-oT4CZALRPBLHqojPTzLDdz4abEMTLuP2ZqhsaZYx6Hma0VOZgy2yopFiL8Zu-Aujxsi-7AEAwstdidVb2alWQBtgYBn7TLZBAmVBbWgq93PcdNYCRHh8l4SVOOm37uUc2puabj70AouM8"
//         }
//       })
//         // .then((res) => res.json())
//         // .then((json) => {
//         //   console.log(json.users)
//         //   data.user = [...json.users]
//         // })
//         const res = await response.json()
//         data = {users: res.users}

//         console.log(data)


// })()

// GET ALL DATA
mock.onGet('/api/users/list/all-data').reply(async () => { 
  const res = await fetch(`https://backend.mypagesmm.com/users?role=user&sort=createdAt&page=0`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2M2Q3NTUyZDhlNGYxODUwNTA0MGVlZGYiLCJyb2xlIjoic3VwZXIgYWRtaW4iLCJpYXQiOjE2NzUwNTY0Mjk2NTYsImV4cCI6MTY3NTA1NzAzNDQ1Nn0.Q9R7pWqkXw3ms_x3vincCz6w5_b2NogQp698R62v87vpPTnHfc2zRsW_PkuwrmMUyE9qSrZ2JjWYjBHQFQQZcYWQggAzqX4Orj271GIpJ_Z_g4Miko7vbRe0SNtjOjnYq7-kxn95y8L-tYa21pTM8Q3xC7zv-cJGGdwBAsNJ1hWWv6evlGU9hLg9JN6oOooX3jae_4Pl9BpUJvF3fTXgJEcUGsawNkeRlDvUoqNr2K9ggk7StoD1odUCuWpfOghDU1G0Q-EjNHGEBq0ccybdf6aSC17a9rSNppNoAs-3WSflKLNTe-ZnYZQ2qKc91wVm_4uMK6cNmm0UmRCwI-tCutT4WSNpQR2xdcSx9YPffYoMlhsIsevy81wMDsx01XGQlLrGHf1c3ksvfYThHF4jNpNvObFhSoMoXkwJuRxDmrUIP60VC7CXD2BLai0M2szMIhux6Hva_fcwVVlj-d1IBkQMrgbOEm9DouFThz2Of-PbXXKFRbct7ytBSVcjGarY69CjjgGbeuKeDM9BBFPXv8StVK82mAh5zLT8LgFosJ1ni9vyNtP7kDIwkC5IIZKJOXUcvCM0fl0NxFofz0i5Uat0M2bB1zz74D2Teca8GU7qZyXDQdE2jR_3qZTbInUbH_bE7HRVOw6jmxpn6MF32u3Y5BANZZh50ojCS8uTwSg"
        }
      })
        // .then((res) => res.json())
        // .then((json) => {
        //   console.log(json.users)
        //   return [200, { user }]
        // })
        const response = await res.json()
        return [200, response]
      }
)

// GET ALL ADMINS 
mock.onGet('/api/admins/list/all-data').reply(async () => { 
  const res = await fetch(`https://backend.mypagesmm.com/users/dashboardUsers?sort=createdAt`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2M2Q3NTUyZDhlNGYxODUwNTA0MGVlZGYiLCJyb2xlIjoic3VwZXIgYWRtaW4iLCJpYXQiOjE2NzUwNTY0Mjk2NTYsImV4cCI6MTY3NTA1NzAzNDQ1Nn0.Q9R7pWqkXw3ms_x3vincCz6w5_b2NogQp698R62v87vpPTnHfc2zRsW_PkuwrmMUyE9qSrZ2JjWYjBHQFQQZcYWQggAzqX4Orj271GIpJ_Z_g4Miko7vbRe0SNtjOjnYq7-kxn95y8L-tYa21pTM8Q3xC7zv-cJGGdwBAsNJ1hWWv6evlGU9hLg9JN6oOooX3jae_4Pl9BpUJvF3fTXgJEcUGsawNkeRlDvUoqNr2K9ggk7StoD1odUCuWpfOghDU1G0Q-EjNHGEBq0ccybdf6aSC17a9rSNppNoAs-3WSflKLNTe-ZnYZQ2qKc91wVm_4uMK6cNmm0UmRCwI-tCutT4WSNpQR2xdcSx9YPffYoMlhsIsevy81wMDsx01XGQlLrGHf1c3ksvfYThHF4jNpNvObFhSoMoXkwJuRxDmrUIP60VC7CXD2BLai0M2szMIhux6Hva_fcwVVlj-d1IBkQMrgbOEm9DouFThz2Of-PbXXKFRbct7ytBSVcjGarY69CjjgGbeuKeDM9BBFPXv8StVK82mAh5zLT8LgFosJ1ni9vyNtP7kDIwkC5IIZKJOXUcvCM0fl0NxFofz0i5Uat0M2bB1zz74D2Teca8GU7qZyXDQdE2jR_3qZTbInUbH_bE7HRVOw6jmxpn6MF32u3Y5BANZZh50ojCS8uTwSg"
        }
      })
        // .then((res) => res.json())
        // .then((json) => {
        //   console.log(json.users)
        //   return [200, { user }]
        // })
        const response = await res.json()
        return [200, response.users]
      }
)

// POST: Add new user
mock.onPost('/apps/users/add-user').reply(config => {
  // Get event from post data
  const {newUser, initialUsers} = JSON.parse(config.data)

  const { length } = initialUsers
  let lastIndex = 0
  if (length) {
    lastIndex = initialUsers[length - 1].id
  }

  initialUsers.unshift(newUser)

// console.log(newUser)

  fetch("https://backend.mypagesmm.com/users", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2M2Q3NTUyZDhlNGYxODUwNTA0MGVlZGYiLCJyb2xlIjoic3VwZXIgYWRtaW4iLCJpYXQiOjE2NzUwNTY0Mjk2NTYsImV4cCI6MTY3NTA1NzAzNDQ1Nn0.Q9R7pWqkXw3ms_x3vincCz6w5_b2NogQp698R62v87vpPTnHfc2zRsW_PkuwrmMUyE9qSrZ2JjWYjBHQFQQZcYWQggAzqX4Orj271GIpJ_Z_g4Miko7vbRe0SNtjOjnYq7-kxn95y8L-tYa21pTM8Q3xC7zv-cJGGdwBAsNJ1hWWv6evlGU9hLg9JN6oOooX3jae_4Pl9BpUJvF3fTXgJEcUGsawNkeRlDvUoqNr2K9ggk7StoD1odUCuWpfOghDU1G0Q-EjNHGEBq0ccybdf6aSC17a9rSNppNoAs-3WSflKLNTe-ZnYZQ2qKc91wVm_4uMK6cNmm0UmRCwI-tCutT4WSNpQR2xdcSx9YPffYoMlhsIsevy81wMDsx01XGQlLrGHf1c3ksvfYThHF4jNpNvObFhSoMoXkwJuRxDmrUIP60VC7CXD2BLai0M2szMIhux6Hva_fcwVVlj-d1IBkQMrgbOEm9DouFThz2Of-PbXXKFRbct7ytBSVcjGarY69CjjgGbeuKeDM9BBFPXv8StVK82mAh5zLT8LgFosJ1ni9vyNtP7kDIwkC5IIZKJOXUcvCM0fl0NxFofz0i5Uat0M2bB1zz74D2Teca8GU7qZyXDQdE2jR_3qZTbInUbH_bE7HRVOw6jmxpn6MF32u3Y5BANZZh50ojCS8uTwSg"
      },
      body: JSON.stringify(newUser)
    })

  return [201, { initialUsers }]
})

// /apps/admins/add-admin

// POST: Add new user
mock.onPost('/apps/admins/add-admin').reply(config => {
  // Get event from post data
  const {newUser, initialUsers} = JSON.parse(config.data)

  const { length } = initialUsers
  let lastIndex = 0
  if (length) {
    lastIndex = initialUsers[length - 1].id
  }

  initialUsers.unshift(newUser)

// console.log(newUser)

  fetch("https://backend.mypagesmm.com/users", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2M2Q3NTUyZDhlNGYxODUwNTA0MGVlZGYiLCJyb2xlIjoic3VwZXIgYWRtaW4iLCJpYXQiOjE2NzUwNTY0Mjk2NTYsImV4cCI6MTY3NTA1NzAzNDQ1Nn0.Q9R7pWqkXw3ms_x3vincCz6w5_b2NogQp698R62v87vpPTnHfc2zRsW_PkuwrmMUyE9qSrZ2JjWYjBHQFQQZcYWQggAzqX4Orj271GIpJ_Z_g4Miko7vbRe0SNtjOjnYq7-kxn95y8L-tYa21pTM8Q3xC7zv-cJGGdwBAsNJ1hWWv6evlGU9hLg9JN6oOooX3jae_4Pl9BpUJvF3fTXgJEcUGsawNkeRlDvUoqNr2K9ggk7StoD1odUCuWpfOghDU1G0Q-EjNHGEBq0ccybdf6aSC17a9rSNppNoAs-3WSflKLNTe-ZnYZQ2qKc91wVm_4uMK6cNmm0UmRCwI-tCutT4WSNpQR2xdcSx9YPffYoMlhsIsevy81wMDsx01XGQlLrGHf1c3ksvfYThHF4jNpNvObFhSoMoXkwJuRxDmrUIP60VC7CXD2BLai0M2szMIhux6Hva_fcwVVlj-d1IBkQMrgbOEm9DouFThz2Of-PbXXKFRbct7ytBSVcjGarY69CjjgGbeuKeDM9BBFPXv8StVK82mAh5zLT8LgFosJ1ni9vyNtP7kDIwkC5IIZKJOXUcvCM0fl0NxFofz0i5Uat0M2bB1zz74D2Teca8GU7qZyXDQdE2jR_3qZTbInUbH_bE7HRVOw6jmxpn6MF32u3Y5BANZZh50ojCS8uTwSg"
      },
      body: JSON.stringify(newUser)
    })

  return [201, { initialUsers }]
})

// GET Updated DATA
mock.onGet('/api/users/list/data').reply(config => {
  const { q = '', perPage = 10, page = 1, role = null, currentPlan = null, status = null } = config
  const {initialUsers} = config

  // console.log(config)

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData = initialUsers?.filter(
    user =>
      (user?.username?.toLowerCase().includes(queryLowered) || user.fullName?.toLowerCase().includes(queryLowered)) &&
      user.role === (role || user.role) &&
      user.currentPlan === (currentPlan || user.currentPlan) &&
      user.status === (status || user.status)
  )
  /* eslint-enable  */

  return [
    200,
    {
      users: paginateArray(filteredData, perPage, page),
      total: filteredData?.length
    }
  ]
})

// /api/admins/list/data
// Get Admin Updated Data 
mock.onGet('/api/admins/list/data').reply(config => {
  const { q = '', perPage = 10, page = 1, role = null, currentPlan = null, status = null } = config
  const {initialUsers} = config

  // console.log(config)

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData = initialUsers?.filter(
    user =>
      (user?.username?.toLowerCase().includes(queryLowered) || user.fullName?.toLowerCase().includes(queryLowered)) &&
      user.role === (role || user.role) &&
      user.currentPlan === (currentPlan || user.currentPlan) &&
      user.status === (status || user.status)
  )
  /* eslint-enable  */

  return [
    200,
    {
      users: paginateArray(filteredData, perPage, page),
      total: filteredData?.length
    }
  ]
})

// /api/roles/list/data
// Get Role Updated Data 
mock.onGet('/api/roles/list/data').reply(config => {
  const { q = '', perPage = 10, page = 1, role = null, currentPlan = null, status = null } = config
  const {initialRoles} = config

  // console.log(initialRoles)

  // /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData = initialRoles?.filter(
    each => (each?.role?.toLowerCase().includes(queryLowered) || each.type?.toLowerCase().includes(queryLowered)) & each.role === (role || each.role) && each.currentPlan === (currentPlan || each.currentPlan) && each.status === (status || each.status))
  // /* eslint-enable  */

  return [
    200,
    {
      users: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

// /api/packages/list/data
// Get package updated data 
mock.onGet('/api/packages/list/data').reply(config => {
  const { q = '', perPage = 10, page = 1, role = null, currentPlan = null, status = null } = config
  const {initialPackages} = config

  // /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData = initialPackages?.filter(
    each => (each?.name?.toLowerCase().includes(queryLowered) || each.description?.toLowerCase().includes(queryLowered)) &&
      each.role === (role || each.role) &&
      each.currentPlan === (currentPlan || each.currentPlan) &&
      each.status === (status || each.status)
  )
  // /* eslint-enable  */

  return [
    200,
    {
      packages: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

// GET Updated DATA
mock.onGet('/api/books/list/data').reply(config => {
  const { q = '', perPage = 10, page = 1, role = null, currentPlan = null, status = null } = config
  const {initialBooks} = config

  // console.log(initialBooks)

  // /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData = initialBooks?.filter(
    book => (book?.title?.toLowerCase().includes(queryLowered) || book.author?.toLowerCase().includes(queryLowered)) &&
      book.role === (role || book.role) &&
      book.currentPlan === (currentPlan || book.currentPlan) &&
      book.status === (status || book.status)
  )
  // /* eslint-enable  */

  return [
    200,
    {
      users: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

// GET USER
mock.onGet('/api/users/user').reply(config => {
  const { id, initialUsers } = config
  // console.log(config)
  const user = initialUsers?.find(i => {
    // console.log(typeof i._id)
    return i._id === id
  })
  return [200, { user }]
})

// Get Admin
mock.onGet('/api/admins/admin').reply(config => {
  const { id, initialUsers } = config
  // console.log(config)
  const user = initialUsers?.find(i => {
    // console.log(typeof i._id)
    return i._id === id
  })
  return [200, { user }]
})

// DELETE: Deletes User
mock.onDelete('/apps/users/delete').reply(config => {

  // Get user id from URL
  const userId = config.id
  const initialUsers = config.initialUsers
  // console.log(userId)

  fetch(`https://backend.mypagesmm.com/users/${userId}`, {
    method: 'DELETE',
    headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2M2Q3NTUyZDhlNGYxODUwNTA0MGVlZGYiLCJyb2xlIjoic3VwZXIgYWRtaW4iLCJpYXQiOjE2NzUwNTY0Mjk2NTYsImV4cCI6MTY3NTA1NzAzNDQ1Nn0.Q9R7pWqkXw3ms_x3vincCz6w5_b2NogQp698R62v87vpPTnHfc2zRsW_PkuwrmMUyE9qSrZ2JjWYjBHQFQQZcYWQggAzqX4Orj271GIpJ_Z_g4Miko7vbRe0SNtjOjnYq7-kxn95y8L-tYa21pTM8Q3xC7zv-cJGGdwBAsNJ1hWWv6evlGU9hLg9JN6oOooX3jae_4Pl9BpUJvF3fTXgJEcUGsawNkeRlDvUoqNr2K9ggk7StoD1odUCuWpfOghDU1G0Q-EjNHGEBq0ccybdf6aSC17a9rSNppNoAs-3WSflKLNTe-ZnYZQ2qKc91wVm_4uMK6cNmm0UmRCwI-tCutT4WSNpQR2xdcSx9YPffYoMlhsIsevy81wMDsx01XGQlLrGHf1c3ksvfYThHF4jNpNvObFhSoMoXkwJuRxDmrUIP60VC7CXD2BLai0M2szMIhux6Hva_fcwVVlj-d1IBkQMrgbOEm9DouFThz2Of-PbXXKFRbct7ytBSVcjGarY69CjjgGbeuKeDM9BBFPXv8StVK82mAh5zLT8LgFosJ1ni9vyNtP7kDIwkC5IIZKJOXUcvCM0fl0NxFofz0i5Uat0M2bB1zz74D2Teca8GU7qZyXDQdE2jR_3qZTbInUbH_bE7HRVOw6jmxpn6MF32u3Y5BANZZh50ojCS8uTwSg'
    }
})
.then(res => res.json())
// .then(json => console.log(json))


  const removedDeletedDatas = initialUsers.filter((each) => {
    return each.id !== userId
  })


  return [200, removedDeletedDatas]
})


// /apps/admins/delete
// DELETE ADMIN 

mock.onDelete('/apps/admins/delete').reply(config => {

  // Get user id from URL
  const userId = config.id
  const initialUsers = config.initialUsers
  // console.log(userId)

  fetch(`https://backend.mypagesmm.com/users/${userId}`, {
    method: 'DELETE',
    headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2M2Q3NTUyZDhlNGYxODUwNTA0MGVlZGYiLCJyb2xlIjoic3VwZXIgYWRtaW4iLCJpYXQiOjE2NzUwNTY0Mjk2NTYsImV4cCI6MTY3NTA1NzAzNDQ1Nn0.Q9R7pWqkXw3ms_x3vincCz6w5_b2NogQp698R62v87vpPTnHfc2zRsW_PkuwrmMUyE9qSrZ2JjWYjBHQFQQZcYWQggAzqX4Orj271GIpJ_Z_g4Miko7vbRe0SNtjOjnYq7-kxn95y8L-tYa21pTM8Q3xC7zv-cJGGdwBAsNJ1hWWv6evlGU9hLg9JN6oOooX3jae_4Pl9BpUJvF3fTXgJEcUGsawNkeRlDvUoqNr2K9ggk7StoD1odUCuWpfOghDU1G0Q-EjNHGEBq0ccybdf6aSC17a9rSNppNoAs-3WSflKLNTe-ZnYZQ2qKc91wVm_4uMK6cNmm0UmRCwI-tCutT4WSNpQR2xdcSx9YPffYoMlhsIsevy81wMDsx01XGQlLrGHf1c3ksvfYThHF4jNpNvObFhSoMoXkwJuRxDmrUIP60VC7CXD2BLai0M2szMIhux6Hva_fcwVVlj-d1IBkQMrgbOEm9DouFThz2Of-PbXXKFRbct7ytBSVcjGarY69CjjgGbeuKeDM9BBFPXv8StVK82mAh5zLT8LgFosJ1ni9vyNtP7kDIwkC5IIZKJOXUcvCM0fl0NxFofz0i5Uat0M2bB1zz74D2Teca8GU7qZyXDQdE2jR_3qZTbInUbH_bE7HRVOw6jmxpn6MF32u3Y5BANZZh50ojCS8uTwSg'
    }
})
.then(res => res.json())
// .then(json => console.log(json))


  const removedDeletedDatas = initialUsers.filter((each) => {
    return each.id !== userId
  })


  return [200, removedDeletedDatas]
})