// ** Initial State
const initialState = {
  allData: [],
  allNotis: [],
  data: [],
  total: 1,
  params: {},
  currentUser: null,
  selectedUser: null,
  selectedNoti: null,
  bookEdit: false,
  customPagination: null,
  token: JSON.parse(localStorage.getItem("accessToken"))
}

// console.log('sssssssssssss', selectedUser)

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_NOTIS':
      return { ...state, allNotis: action.data }
    case 'GET_BOOK_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_USER':
      // console.log(action)
      return { ...state, selectedUser: action.selectedUser }
    case 'ADD_USER':
      return { ...state, allData: action.data }
    case 'DELETE_BOOK':
      // console.log(action.data)
      return { ...state, allNotis: action.data }
    case 'EDITNOTI':
      return { ...state, selectedNoti: action.data }
    case 'BOOK_PAGE_CHANGE':
      return { ...state, allNotis: [] }
    default:
      return { ...state }
  }
}
export default notifications
