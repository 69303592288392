// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
import chat from "@src/views/apps/chat/store/reducer";
import todo from "@src/views/apps/todo/store/reducer";
import users from "@src/views/apps/usersRoute/user/store/reducer";
import admins from "../../views/apps/adminRoute/user/store/reducer";
import email from "@src/views/apps/email/store/reducer";
import invoice from "@src/views/apps/invoice/store/reducer";
import calendar from "@src/views/apps/calendar/store/reducer";
import ecommerce from "@src/views/apps/ecommerce/store/reducer";
import dataTables from "@src/views/tables/data-tables/store/reducer";
import roles from "@src/views/apps/roleSetting/user/store/reducer";
import notifications from "../../views/apps/notificationSetting/user/store/reducer";
import userLog from "../../views/apps/userLog/user/store/reducer";
import auditLog from "../../views/apps/auditLog/user/store/reducer";
import socket from "../../views/apps/socket.io/store/reducer";
import plans from "../../views/apps/Plans/main/store/reducer";
import ranges from "../../views/apps/ranges/store/reducer";
import subscription from "../../views/apps/subscription/main/store/reducer";
import senderID from "../../views/apps/senderID/user/store/reducer";
import operators from "../../views/apps/operators/user/store/reducer";
import invoiceHistory from "../../views/apps/invoiceHistory/main/store/reducer";
import smsLogs from "../../views/apps/smsLogs/main/store/reducer";
import smsTypes from "../../views/apps/smsTypes/user/store/reducer";
import spamWords from "../../views/apps/spamWords/user/store/reducer";
import campaign from "../../views/apps/campaign/main/store/reducer";

const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  admins,
  roles,
  notifications,
  userLog,
  auditLog,
  socket,
  plans,
  ranges,
  subscription,
  senderID,
  operators,
  invoiceHistory,
  smsLogs,
  smsTypes,
  spamWords,
  campaign,
});

export default rootReducer;
