// ** Initial State
const initialState = {
  allData: [],
  smsTypes: [],
  data: [],
  total: 1,
  params: {},
  currentUser: null,
  selectedUser: null,
  selectedSMSType: null,
  bookEdit: false,
  token: JSON.parse(localStorage.getItem("accessToken")),
  customPagination: null,
  found_results: "",
  entries_per_page: "",
  total_results: "",
};

// console.log('sssssssssssss', selectedUser)

const smsTypes = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_ROLE":
      // return { ...state, smsTypes: [...action.data] }
      return {
        ...state,
        smsTypes: [...action.data],
        customPagination: action.customTotal,
        entries_per_page: action.entries_per_page,
        found_results: action?.found_results,
        total_results: action?.total_results,
      };

    case "GET_ROLE_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_USER":
      // console.log(action)
      return { ...state, selectedUser: action.selectedUser };
    case "ADD_USER":
      return { ...state, allData: action.data };
    case "DELETE_SMSTYPE":
      // console.log(action.data)
      return { ...state, smsTypes: action.data };
    case "EDITSMSTYPE":
      return { ...state, selectedSMSType: action.data };
    default:
      return { ...state };
  }
};
export default smsTypes;
